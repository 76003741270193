<template>
  <div class="pt-6">
    <v-table>
      <tr>
        <td />
        <td>
          <!-- eslint-disable vue/no-v-html -->
          <div
            style="word-wrap: break-word"
            v-html="`<p>${feature.metadata?.informative?.description.replace(/\n/gi, '</p><p>')}</p>`"
          />

          <div
            v-if="feature.metadata?.informative?.referenceUrls && feature.metadata.informative?.referenceUrls[0]"
            class="mt-4"
          >
            <a :href="feature.metadata.informative.referenceUrls[0]" class="text-blue" target="_blank" rel="noopener">
              More info
            </a>
          </div>
        </td>
        <td>
          <div class="text-truncate text-no-wrap">
            {{ getProjectTeam(feature) || 'No team info' }}
          </div>
          <br />
          <div class="text-truncate text-no-wrap">
            {{
              feature.metadata?.changeRecord?.createdBy ||
              feature.metadata?.informative?.additionalData?.createdBy ||
              'No contact info'
            }}
          </div>
        </td>
        <td>
          <div v-for="(parameter, i) in feature.parameters.filter((p: any) => !p.deprecated)" :key="i">
            <v-row class="justify-space-between mb-3" style="border-bottom: 1px dotted rgba(0, 0, 0, 0.3); margin: 0">
              <v-col cols="6" style="padding: 0; word-wrap: break-word">
                {{ parameter.name }}
              </v-col>
              <v-col cols="6" class="text-right" style="padding: 0; word-wrap: break-word">
                {{ transformParamValue((parameter.type!.oneOf as any)[parameter.type!.oneOf!.$case].defaultValue) }}
              </v-col>
            </v-row>
          </div>
        </td>
        <td class="text-right pr-8">
          <div>Created</div>
          <br />

          {{
            feature.metadata?.changeRecord?.createdAt
              ? $dayjs(feature.metadata?.changeRecord?.createdAt).format('DD MMM YYYY')
              : ''
          }}
        </td>
      </tr>
    </v-table>

    <div class="d-flex flex-column flex-sm-row mx-2 my-4">
      <StatisticsChip
        class="ml-10"
        stat="count"
        unit="users"
        label="Enabled for"
        :loading="isLoading"
        :stats-data="featureStats"
        @open="statisticsChartRef.open()"
      />

      <StatisticsChart
        ref="statisticsChartRef"
        unit="day"
        title="Active users with feature flag enabled"
        :loading="isLoading"
        :header="feature.metadata?.name?.toUpperCase()"
        :guides="featureEvents"
        :stats-data="featureStats"
        :labels="['Users with flag enabled', 'Users with flag enabled current day']"
      />

      <v-spacer />

      <v-btn
        class="mr-2"
        color="primary"
        prepend-icon="mdi-comment-outline"
        :text="(notesCount ? notesCount + ' ' : '') + (notesCount === 1 ? 'Note' : 'Notes')"
        @click="emitNotes()"
      />

      <v-tooltip location="top end" :disabled="isProjectEditor(feature)">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="mr-2"
            text="Modify"
            color="primary"
            prepend-icon="mdi-pencil-outline"
            :disabled="!isProjectEditor(feature)"
            @click="emitEdit()"
          />
        </template>
        <div>Request access from the project owner</div>
        {{ (ffOwningProject && ffOwningProject.owner) || '' }}
      </v-tooltip>

      <!-- <v-tooltip
        v-if="!feature.metadata.objectType"
        location="top end"
        :disabled="feature.metadata.owners === userEmail"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="red"
            class="mr-2"
            text="Withdraw"
            prepend-icon="mdi-trash-can-outline"
            :disabled="true"
            @click="withdrawFeature()"
          />
        </template>
        <div>Only owner can withdraw this</div>
        {{ feature.metadata.owners }}
      </v-tooltip> -->

      <!-- <v-tooltip v-if="!feature.metadata.objectType" location="top end" :disabled="isAppFeatureAdmin">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="mr-2"
            color="green"
            text="Accept"
            prepend-icon="mdi-check"
            :disabled="true"
            @click="acceptFeature()"
          />
        </template>
        <div>Only system admins can accept this</div>
      </v-tooltip> -->

      <v-tooltip location="top end" :disabled="!isProjectEditor(feature)">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="red"
            class="mr-2"
            text="Deprecate"
            prepend-icon="mdi-trash-can-outline"
            :disabled="!!feature?.deprecations?.length"
            @click="confirmDeprecation = true"
          />
        </template>
        <div>Only feature admins can deprecate this</div>
      </v-tooltip>

      <v-btn class="mr-2" color="green" text="Rollouts" prepend-icon="mdi-rocket-launch" @click="emitRollouts()" />
    </div>
  </div>

  <v-dialog v-model="confirmDeprecation" width="800">
    <v-card>
      <v-card-title>Confirm feature flag deprecation</v-card-title>

      <v-card-text>
        You are about to deprecate the following feature flag:
        <b>{{ getProjectKey(feature) + '/' + feature.metadata?.name }}</b>

        <template v-if="!feature.overrideList.length">
          <v-text-field v-model="deprecateRationale" class="mt-4" label="Reason for deprecation *" autofocus />
        </template>

        <template v-else>
          <v-alert type="info" class="my-6">
            The current active rollouts will remain active and you can not undo this action, deprecation is a permanent
            action! Its good practice to review the rollouts before deprecating a flag. You can find detailed
            instructions in the
            <a
              href="https://ouraring.atlassian.net/wiki/spaces/SW/pages/4068115084/Feature+Flags+Management#Deprecating-a-feature-flag"
              target="_blank"
            >
              documentation
            </a>
            .
          </v-alert>

          <v-text-field v-model="deprecateRationale" class="mt-4" label="Reason for deprecation *" autofocus />

          <v-text-field v-model="deprecateReference" label="Additional info url to a JIRA ticket" />
        </template>

        <v-alert type="warning">
          Automatic pull request to remove the flag definition will be made for all Oura app platforms and once merged
          the Oura app will no longer receive the feature flag information from the backend.
          <b>You should have already removed the related code from the applications before deprecating the flag.</b>
          Otherwise the definition removal PR wont get merged and updating the definitions is blocked until flag
          referencing is removed.
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" @click="confirmDeprecation = false" />

        <v-btn
          color="red"
          text="Confirm"
          :disabled="isLoading || !deprecateRationale"
          :loading="isLoading"
          @click="deprecateFeature()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, getFirestore, onSnapshot, query } from 'firebase/firestore'

  import { fixedPointToString, forEachFeatureOverride } from '#views/features/utilities'
  import { getProjectKey, getProjectTeam, isProjectEditor } from '#views/projects/utilities'

  import { AppStore, FeaturesStore, ProjectsStore } from '#stores'

  import { Dialog, Feature } from '#types'

  @Component({})
  class FeatureDrawer extends Vue {
    @Prop() public feature!: Feature

    public notesCount = 0

    public deprecateRationale = ''
    public deprecateReference = ''

    public confirmDeprecation = false

    public readonly getProjectKey = getProjectKey
    public readonly getProjectTeam = getProjectTeam
    public readonly isProjectEditor = isProjectEditor

    private readonly appStore = new AppStore()

    private readonly featuresStore = new FeaturesStore()
    private readonly projectsStore = new ProjectsStore()

    @Ref() public readonly statisticsChartRef!: Dialog

    private flagNotesUnsubscribe: Unsubscribe | null = null

    public get userEmail() {
      return this.appStore.user?.email
    }

    public get isLoading() {
      return this.featuresStore.loading
    }

    public get featureStats() {
      if (this.featuresStore.stats?.seriesLabels) {
        const name = `${getProjectKey(this.feature)}/${this.feature.metadata?.name}`

        const index = this.featuresStore.stats?.seriesLabels.findIndex((item: any) => item[1] === name)

        if (index > -1) {
          const seriesForId = this.featuresStore.stats?.series[index]

          return { xValues: this.featuresStore.stats?.xValues, series: seriesForId }
        }
      }

      return null
    }

    public get featureEvents() {
      const events: any[] = []

      if (this.featureStats) {
        forEachFeatureOverride(this.feature, (override) => {
          if (override.rolloutOneOf?.$case === 'rollout') {
            override.rolloutOneOf?.rollout?.stages.forEach((s: any, i: number) => {
              if (
                s.scheduledAt &&
                this.$dayjs(s.scheduledAt) > this.$dayjs(this.featureStats!.xValues[0]).add(1, 'day')
              ) {
                events.push({
                  label: override.metadata?.uid?.split('_').slice(0, -1).join('_').toUpperCase() + ' - Step ' + (i + 1),
                  value: this.$dayjs(s.scheduledAt).valueOf(),
                })
              }
            })
          }
        })
      }

      return events
    }

    public get ffOwningProject() {
      return this.projectsStore.projects.find((p) => p.id === this.feature.metadata?.informative?.labels.project)
    }

    public get isAppFeatureAdmin() {
      return this.appStore.isAppFeatureAdmin
    }

    @Emit('edit')
    public emitEdit() {
      return this.feature
    }

    @Emit('notes')
    public emitNotes() {
      return this.feature
    }

    @Emit('rollouts')
    public emitRollouts() {
      return this.feature
    }

    @Watch('feature', { immediate: true })
    protected featureChanged(val: any, oldVal: any) {
      if (val?.metadata.name !== oldVal?.metadata.name) {
        if (this.flagNotesUnsubscribe) {
          this.flagNotesUnsubscribe()
        }

        this.flagNotesUnsubscribe = onSnapshot(
          query(collection(getFirestore(), `/features/${this.feature.metadata?.name}/notes`)),
          (snap) => {
            this.notesCount = snap.docs.length
          },
        )
      }
    }

    public beforeUnmount() {
      if (this.flagNotesUnsubscribe) {
        this.flagNotesUnsubscribe()

        this.flagNotesUnsubscribe = null
      }
    }

    public transformParamValue(value: any) {
      if (typeof value === 'object' && value.sign !== undefined) {
        return fixedPointToString(value)
      } else if (typeof value === 'object' && value.seconds !== undefined) {
        return value.seconds
      }

      return value
    }

    public async deprecateFeature() {
      await this.featuresStore.deprecateFeature(this.feature, this.deprecateRationale, [this.deprecateReference])

      this.confirmDeprecation = false
    }
  }

  export default toNative(FeatureDrawer)
</script>
